import React, { useCallback } from 'react'
// import { loadStripe } from '@stripe/stripe-js'
// import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { Close, CreditCard } from '@mui/icons-material'

import * as TRANSACTION_INFORMATIONS_REQUESTS from '../../../api/transactionInformations'
import { DATE_FORMAT, GRAY_TEXT_COLOR } from '../../../utils/constants'
import moment from 'moment'
// import { STRIPE_PUBLIC_KEY } from '../../../utils/config'

// const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const StripeCheckoutForm = ({
  data,
  startFrom,
  operationalServicesFee,
  onCancel,
  company,
  subscription
}) => {
  const { t } = useTranslation()
  // const [options, setOptions] = useState()

  const fetchClientSecret = useCallback(async () => {
    try {
      const response = await TRANSACTION_INFORMATIONS_REQUESTS.createStripeCheckoutSession({
        subscriptionIds: data.subscriptionIds,
        postalCode: data.postalCode,
        slotId: data.slotId,
        address: data.address,
        ...(data.voucherId ? { voucherId: data.voucherId } : {}),
        ...(data.userVoucherId ? { userVoucherId: data.userVoucherId } : {}),
        validFrom: moment(startFrom).add(3, 'hours'),
        cancelUrl: window.location.href,
        operationalServicesFee: operationalServicesFee
      })
      if (response) {
        window.location.href = response; // Redirect to the Stripe URL
      } else {
        console.error("No URL returned from the backend");
      }
    } catch (error) {
      return console.error(error)
    }
  }, [])
  // const onPayWithPopupHandler = useMemo(() => {
  //   try {
  //     return (
  //       <Grid maxWidth="99%">
  //         <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
  //           <EmbeddedCheckout />
  //         </EmbeddedCheckoutProvider>
  //       </Grid>
  //     )
  //   } catch (error) {
  //     return console.error(`Error on stripe checkout page: ${error}`)
  //   }
  // }, [options])

  return (
    <Dialog
      open
      fullScreen
    // PaperProps={{ style: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }}
    >
      {/* {!options ? ( */}
      <Container maxWidth="sm">
        <Grid container pt={2} spacing={2}>
          {/* Header section */}
          <Grid item xs={12}>
            <Grid container display="flex" flexDirection="row">
              <Grid item xs={10} md={10} lg={10}>
                <Typography variant="h4" fontWeight={500} color={'black'}>
                  {t('checkoutPayment.title')}
                </Typography>
              </Grid>
              <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                <IconButton onClick={onCancel}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {/* Club info section */}
          <Grid item xs={12} display="flex" alignItems="center" mt={6}>
            <Avatar src={company.avatarPhotoSrc} sx={{ mr: 1, height: 60, width: 60 }} />
            <Box>
              <Typography fontWeight="500" variant="h4">
                {company?.name}
              </Typography>
              <Typography fontWeight="500" variant="p">
                {company.locationName}
              </Typography>
            </Box>
          </Grid>

          {/* Subscription info section */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography color={GRAY_TEXT_COLOR} variant="p">
              {t('checkoutPayment.buySubscription')}{' '}
              <strong style={{ color: 'black' }}>{subscription?.name}</strong>{' '}
              {t('checkoutPayment.startingFrom')}{' '}
              <strong style={{ color: 'black' }}>{moment(startFrom).format(DATE_FORMAT)}</strong>
            </Typography>
          </Grid>

          {/* Button to create Stripe checkout session */}
          <Grid item xs={12}>
            <Button
              // disabled={loading}
              variant="contained"
              fullWidth
              onClick={fetchClientSecret}>
              <CreditCard fontSize="large" sx={{ pr: 2 }} />{' '}
              <Typography variant="h6" fontWeight="bold">
                Card
              </Typography>
            </Button>
          </Grid>
          {/* <Grid maxWidth="99%">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Grid> */}
        </Grid>
      </Container>
      {/* // ) : (
      //   <Container maxWidth="lg">
      //     <Grid pt={2} spacing={2}>
      //       <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
      //         <IconButton onClick={onCancel}>
      //           <Close />
      //         </IconButton>
      //       </Grid>
      //       {onPayWithPopupHandler}
      //     </Grid>
      //   </Container>
      // )} */}
    </Dialog>
  )
}

export default StripeCheckoutForm
