import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

import './index.css'
import './i18n/config'
import Gleap from 'gleap';
// import { Userpilot } from 'userpilot';

// Please make sure to call this method only once!
Gleap.initialize("UIIj9p1fEHzjeFhIEvSxbwq2wjzF9o1D");
// Userpilot.initialize('NX-af8833a6')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
