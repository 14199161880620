import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography, CircularProgress, TextField, IconButton, Box, FormControl, MenuItem, Select, Card, Tooltip, Avatar, AvatarGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowBackIos, Check, Close } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { Table } from '../../../common'

import * as REPORTS_REQUESTS from '../../../../api/reports'
import { useAuthentication } from '../../../../context/auth-context'
import { GRAY_TEXT_COLOR, DATE_TIME_FORMAT, PRIMARY_COLOR, SPORTTYPES_TYPES } from '../../../../utils/constants'

const Filters = ({ onStartChange, onEndChange, startValue, endValue, sportType, onSportTypeChange, participants, onParticipantsChange }) => {
    const { t } = useTranslation()

    return (
        <Grid container display="flex" flexDirection="row" alignItems="center" spacing={1} pt={1}>
            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500} gutterBottom>{t('reports.revenue.startTime')}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={moment(startValue)}
                        onChange={(newValue) => {
                            if (Date.parse(newValue)) {
                                onStartChange(moment(new Date(newValue).toISOString().split('T')[0]))
                            }
                        }}
                        renderInput={(params) => <TextField
                            fullWidth
                            sx={{
                                '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                        />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500} gutterBottom>{t('reports.revenue.endTime')}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={moment(endValue)}
                        onChange={(newValue) => {
                            if (Date.parse(newValue)) {
                                onEndChange(moment(new Date(newValue).toISOString().split('T')[0]))
                            }
                        }}
                        renderInput={(params) => <TextField
                            fullWidth
                            sx={{
                                '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                        />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item lg={3} xs={6}>
                <Typography variant="p" fontWeight={500} gutterBottom>Sport Types</Typography>
                <FormControl fullWidth>
                    <Select
                        value={sportType}
                        onChange={event => onSportTypeChange(event.target.value)}
                    >
                        <MenuItem value="">
                            <em>{t('reports.events.all')}</em>
                        </MenuItem>
                        {Object.keys(SPORTTYPES_TYPES).map(el => {
                            return <MenuItem key={el} value={el}>{t(`sportTypesOptions.${el}`)}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item lg={3} xs={6}>
                <Typography variant="p" fontWeight={500} gutterBottom>Participants</Typography>
                <FormControl fullWidth>
                    <Select
                        value={participants}
                        onChange={event => onParticipantsChange(event.target.value)}
                    >
                        <MenuItem value="">
                            <em>{t('reports.events.all')}</em>
                        </MenuItem>
                        <MenuItem value={'WITH_PARTICIPANTS'}>{t('reports.events.withParticipants')}</MenuItem>
                        <MenuItem value={'WITHOUT_PARTICIPANTS'}>{t('reports.events.withoutParticipants')}</MenuItem>
                        <MenuItem value={'WITH_CONFIRMED_PARTICIPANTS'}>{t('reports.events.withConfirmedParticipants')}</MenuItem>
                        <MenuItem value={'WITHOUT_CONFIRMED_PARTICIPANTS'}>{t('reports.events.withoutConfirmedParticipants')}</MenuItem>
                        <MenuItem value={'WITH_PARTICIPANTS_WAITING_LIST'}>{t('reports.events.withParticipantsInWaitingList')}</MenuItem>
                        <MenuItem value={'WITHOUT_PARTICIPANTS_WAITING_LIST'}>{t('reports.events.withoutParticipantsInWaitingList')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [start, setStart] = useState()
    const [eventUsersData, setEventUsersData] = useState()
    const [page, setPage] = useState(1)
    const [end, setEnd] = useState()
    const [sportType, setSportType] = useState('')
    const [participants, setParticipants] = useState('')

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth()
        const firstDay = new Date(year, month, 1)
        const lastDay = new Date(year, month + 1, 0)

        setStart(moment(firstDay.toISOString().split('T')[0]))
        setEnd(moment(lastDay.toISOString().split('T')[0]))
    }, [])

    useEffect(() => {
        if (!start || !end) return

        setLoading(true)

        REPORTS_REQUESTS.getUsers({
            start: moment(start).startOf('day').toISOString().split('T')[0],
            end: moment(end).startOf('day').toISOString().split('T')[0]
        })
            .then(result => {
                setEventUsersData(result)
            })
            .finally(() => setLoading(false))
    }, [user, start, end, page])

    return (
        <Container maxWidth="xl">
            <Grid container pt={4} pb={5} spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconButton onClick={() => navigate('/reports')}><ArrowBackIos fontSize='small' sx={{ color: 'black' }} /></IconButton>
                        <Typography variant="h6" fontWeight={'bold'}>
                            {t('reports.clients.ReportsClients')}
                        </Typography>
                    </Box>

                    <Filters
                        sportType={sportType}
                        onSportTypeChange={setSportType}
                        endValue={end}
                        startValue={start}
                        onEndChange={setEnd}
                        onStartChange={setStart}
                        onParticipantsChange={setParticipants}
                        participants={participants}
                    />
                </Grid>

                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                    {loading ? <CircularProgress /> : !eventUsersData ? null : <Grid container display="flex" flexDirection="column" spacing={1}>
                        {Object.values(eventUsersData).filter(el => {
                            let condition = true

                            if (sportType) {
                                condition = condition && el.event.sportType && el.event.sportType.toLocaleLowerCase() == sportType.toLocaleLowerCase()
                            }

                            if (participants && participants !== '') {
                                if (participants === 'WITH_PARTICIPANTS') {
                                    condition = condition && el.users.length
                                } else if (participants === 'WITHOUT_PARTICIPANTS') {
                                    condition = condition && !el.users.length
                                } else if (participants === 'WITH_CONFIRMED_PARTICIPANTS') {
                                    condition = condition && el.users.some(participant => participant.isCheckedIn)
                                } else if (participants === 'WITHOUT_CONFIRMED_PARTICIPANTS') {
                                    condition = condition && !el.users.some(participant => participant.isCheckedIn)
                                } else if (participants === 'WITH_PARTICIPANTS_WAITING_LIST') {
                                    condition = condition && el.users.some(participant => participant.isInWaitingList)
                                } else if (participants === 'WITHOUT_PARTICIPANTS_WAITING_LIST') {
                                    condition = condition && !el.users.some(participant => participant.isInWaitingList)
                                }
                            }

                            return condition
                        }).map(el => {
                            return <Grid item xs={12} pt={2} pb={2}>
                                <Grid container spacing={1} component={Card} p={2}>
                                    <Grid item xs={12}>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Box>
                                                <Typography variant="p" fontWeight="bold">
                                                    {el.event.name}
                                                </Typography>
                                                {el.event.sportType && el.event.sportType !== 'NONE' ? <Typography variant="body2" fontWeight={500} color={PRIMARY_COLOR}>
                                                    {t(`sportTypesOptions.${el.event.sportType}`)}
                                                </Typography> : null}
                                                <Typography variant="body2" fontWeight={500} color={GRAY_TEXT_COLOR}>
                                                    {moment(el.event.start).subtract(3, 'hours').format(DATE_TIME_FORMAT)}
                                                </Typography>
                                            </Box>
                                            <Box pl={1} display="flex" flexDirection="row" alignItems="center">
                                                <AvatarGroup>
                                                    {el.users.map(el => {
                                                        return <Tooltip title={el.firstName + ' ' + el.lastName}>
                                                            <Avatar alt={el.firstName} key={el.id} src={el.avatarPhotoSrc}>
                                                                {el.firstName[0] + el.lastName[0]}
                                                            </Avatar>
                                                        </Tooltip>
                                                    })}
                                                </AvatarGroup>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} pr={1}>
                                        {!el.users.length ? <Typography variant="body2" color={GRAY_TEXT_COLOR}>{t('reports.events.nobooked')}</Typography> : <Table
                                            dense
                                            headCells={[
                                                t('reports.events.firstName'),
                                                t('reports.events.lastName'),
                                                t('reports.events.phoneNumber'),
                                                t('reports.events.email'),
                                                t('reports.events.bookedDate'),
                                                t('reports.events.checkedIn'),
                                                t('reports.events.isInWaitingList')
                                            ]}
                                            rows={el.users.map(user => {
                                                return {
                                                    firstName: user.firstName,
                                                    lastName: user.lastName,
                                                    phoneNUmber: user.phoneNumber,
                                                    email: user.email,
                                                    bookedDate: moment(user.bookedAt).subtract(3, 'hours').format(DATE_TIME_FORMAT),
                                                    checkedIn: <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>{user.isCheckedIn ? <Check fontSize='small' /> : <Close fontSize='small' />}</Box>,
                                                    isInWaitingList: <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>{user.isInWaitingList ? <Check fontSize='small' /> : <Close fontSize='small' />}</Box>,
                                                }
                                            })}
                                        />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>}
                </Grid>
            </Grid>
        </Container>
    )
}